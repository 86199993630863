import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Response } from '../models';

export const DisplayNameType = {
  PRODUCT: { value: 'PRODUCT', text: '商品単位' },
  TITLE: { value: 'TITLE', text: 'タイトル単位' },
  PRODUCT_GROUP: { value: 'PRODUCT_GROUP', text: '作品単位' },
};

export const ShowDetailHeaderPayeeAddresseesOptions = {
  SHOW: { value: true, text: '全ページに表示する' },
  NOT_SHOW: { value: false, text: '頭紙のみに表示する' },
};

export interface FormSetting {
  tenant_id?: string;
  is_sale_notification_attached?: boolean;
  is_payment_statement_sale_notification_attached?: boolean;
  // MILK 622 追加 bank account
  is_bank_account_attached?: boolean;
  is_other_cost_notification_attached?: boolean;
  is_recoupment_ledger_notification_attached?: boolean;
  payment_statement_cover_body_greeting?: string;
  carryover_payment_statement_cover_body_greeting?: string;
  payment_record_statement_cover_body_greeting?: string;
  corporate_number?: string;
  office_number?: string;
  organization_number?: string;
  royalty_detail_display_name_type?: string;
  royalty_detail_base_amount_display_name?: string;
  show_form_code?: boolean;
  show_detail_header_payee_addressees?: boolean;
  show_royalty_detail_yyyy_mm?: boolean;
  show_royalty_detail_store_sales_yyyymm?: boolean;
  show_royalty_detail_selling_type?: boolean;
  show_royalty_detail_sales_route_name?: boolean;
  show_royalty_detail_store_name?: boolean;
  show_royalty_detail_retail_price?: boolean;
  show_royalty_detail_total_download_count?: boolean;
  show_royalty_detail_base_amount?: boolean;
  show_royalty_detail_royalty_rate?: boolean;
  payment_statement_display_name_type?: string;
  payment_statement_base_amount_display_name?: string;
  show_payment_statement_yyyy_mm?: boolean;
  show_payment_statement_store_sales_yyyymm?: boolean;
  show_payment_statement_selling_type?: boolean;
  show_payment_statement_sales_route_name?: boolean;
  show_payment_statement_store_name?: boolean;
  show_payment_statement_retail_price?: boolean;
  show_payment_statement_total_download_count?: boolean;
  show_payment_statement_base_amount?: boolean;
  show_payment_statement_royalty_rate?: boolean;
}

export class FormDefaultInfo {
  payment_statement_cover_body_greeting?: string;
  carryover_payment_statement_cover_body_greeting?: string;
  payment_record_statement_cover_body_greeting?: string;
  royalty_detail_base_amount_display_name?: string;
  payment_statement_base_amount_display_name?: string;
  adjustable_col_max_width?: number;
  adjustable_col_width_vals?: { [key: string]: number };
}

@Injectable()
export class FormSettingsService {
  constructor(private http: HttpClient) {}

  get(): Observable<Response<FormSetting>> {
    return this.http.get<Response<FormSetting>>(
      `/authorized/form-settings/owned`
    );
  }

  put(d: FormSetting): Observable<Response<any>> {
    return this.http.put<Response<FormSetting>>(
      `/authorized/form-settings/owned`,
      d
    );
  }

  getFormDefaultInfo(): Observable<Response<FormDefaultInfo>> {
    return this.http.get<Response<FormDefaultInfo>>(
      `/authorized/form-settings/owned/form-default-info`
    );
  }
}
